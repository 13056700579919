import React from 'react'
import '../../App.css'
import Footer from '../Footer'
import Coursework from '../Coursework'



function Courses() {
    return (
        <>
            <Coursework />
            <Footer />
        </>

    );
}

export default Courses;