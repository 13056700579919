import React from 'react'
import '../../App.css'
import Footer from '../Footer'
import Project from '../Project'

function Projects() {
    return (
        <>
            <Project />
            <Footer />
        </>

    );
}

export default Projects;