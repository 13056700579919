import React from 'react'
import '../../App.css'
import Footer from '../Footer'
import Alex from '../Alex'

function Home() {
    return (
        <>
            <Alex />
            <Footer/>
        </>

    );
}

export default Home;